import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Departments from "../components/departments"

const DepartmentsPage = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Layout>
          <SEO title="Departments" />
          <Departments />
        </Layout>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default DepartmentsPage
