import React from "react"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"
import Image from "./image"
import ArrowRightAltIcon from "@material-ui/icons/ArrowDownwardOutlined"
import ArrowLeftAltIcon from "@material-ui/icons/ArrowUpwardOutlined"
import { Collapse } from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"

interface IProps {
  article: Article
}

interface IDetails {
  heading: string
  text: string
  list: []
}

interface Article {
  title: string
  image: string
  description: string
  url: string
  details: IDetails[]
}

const DepartmentCard = ({ article, ...props }: IProps) => {

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card style={{ minHeight: "600px" }} className="body-font" >
      <CardMedia image={article.image}>
        <Image alt={article.title} filename={article.image} />
      </CardMedia>
      <CardContent style={{ textAlign: "start" }}>
        <Typography variant="h6" color="inherit">
          {article.title}
        </Typography>
        <Typography align="justify" variant="body2" component="p" className='body-font' >
          {article.description}
        </Typography>
      </CardContent>
      <CardActions style={{ paddingLeft: 12, paddingTop: 0 }}>
        {expanded ?
          <Button size="small" color="primary" onClick={handleExpandClick}>
            Read Less <ArrowLeftAltIcon style={{ marginLeft: 5 }} />
          </Button>
          :
          <Button size="small" color="primary" onClick={handleExpandClick}>
            Read More <ArrowRightAltIcon style={{ marginLeft: 5 }} />
          </Button>
        }
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {article.details.map(element => (
            <Typography align="justify" variant="body2" component="p" className='body-font'>

              <Typography align="justify" variant="h6" >
                {element.heading}
              </Typography>

              <Typography align="justify" variant="h6"  className='body-font'>
                {element.text}
              </Typography>
              <br />
              <div>
                {element.list.map(element => (
                  <div className='body-font'>{element}</div>
                ))}
              </div>
              <br />
            </Typography>
          ))}
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default DepartmentCard
