import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import DepartmentCard from "./department-card"
import Bar from "./bar"
import { Box } from "@material-ui/core"

const Departments = () => {
  const articles = [
    {
      key: 1,
      title: "Special Education",
      description:
        `Special education is a range of services that help kids with disabilities in their learning process. It focuses on helping children with emotional, behavioral, cognitive, motor, neurological, hearing, vision, speech and learning impairments."`,
      url: "",
      image: "special ed.png",
      details: [
        {
          heading: "",
          text: "Special school admission is only available for children who are below 18 years of age and have 40% or more disability. These children need extra attention in learning as it’s likely that they would face difficulties in following the pace and process of learning, when admitted in normal schools. We also encourage children with less than 40% disability to be admitted to normal schools and use the therapy facilities available in Jyothis, so they can benefit from the inclusive education and therapy facilities at the same time.",
          list: []
        },
        {
          heading: "",
          text: "Our aim is to facilitate the holistic development of special children and develop them to be a part of the mainstream society. We follow the curriculum provided by National Institute of Mentally Handicapped, Hyderabad. The children appear for public equivalency exams after completing their preliminary class, 4th standard, 7th standard and 10th standard. Our special educators are Rehabilitation Council of India (RCI) registered and specialized in the field of special education.",
          list: []
        },
        {
          heading: "",
          text: "When a child is admitted to the special education school, the special educators conduct the assessment of child in all domains. Later, based on the age and Intelligence quotient (IQ) of the child, his/her individual goals are prepared and get accommodated into respective class. The classes are divided into six groups:",
          list: ["●	Care group", "●	Pre-primary", "●	Primary", "●	Secondary", "●	Pre- vocational", "●	Vocational"]
        },
        {
          heading: "",
          text: "Progress of the child is evaluated every year through internal examinations and they would be promoted to next class based on the findings of the evaluation.",
          list: []
        },
      ]
    },
    {
      key: 2,
      title: "Vocational training",
      description:
        "Vocational training is an instructional program that prepares special children for an occupation. We provide vocational training program for special children above 18 years and help them acquire vocational skills which enhance their employment opportunities and subsequently help them to lead an independent life.",
      url: "",
      image: "vocational.png",
      details: [
        {
          heading: "",
          text: "We train the students based on their talent and niche skills. Currently our vocational training center provide trainings classes on soap making, candle making, cloth painting, stitching, wool works, embroidery, mat making, paper crafts, painting, necklace making etc. We also provide continued support to the trainees who complete the vocational training program in marketing and selling their products, thus helping them earn for their living.",
          list: []
        }
      ]
    },
    {
      key: 3,
      title: "Speech Therapy and Audiology",
      description:
        "Speech therapy is the assessment and treatment of communication problems and speech disorders. It is performed by speech-language pathologists (SLPs), which are often referred to as speech therapists. Audiology is a branch of science that deals with hearing and hearing related disorders.",
      url: "",
      image: "speech therapY.png",
      details: [
        {
          heading: "",
          text: "Our clinicians provide highly effective therapy sessions for children with intellectual disability, Autism spectrum disorder, cerebral palsy, Down’s syndrome, Learning disability, Speech sound disorder, Attention Deficit Hyperactivity Disorder and Fluency disorders.",
          list: []
        },
        {
          heading: "",
          text: " We are also proud to have an acoustic room with modern equipments and audiometers for effective analysis of clients’ hearing threshold.",
          list: []
        },
        {
          heading: "",
          text: "Speech & Language intervention focus on child directed therapy. We use techniques like Picture Exchange Communication System, Floor Time Therapy, Cognitive-Behavior Therapy, Oral Placement Therapy, Air Flow Techniques, Syllable tapping for children with varied speech and language problems. ",
          list: []
        },
        {
          heading: "",
          text: "When a child is admitted to the speech therapy clinic the clinician assess the child’s birth, developmental and family histories, academics, play behavior, oral examinations and language evaluation using the test materials. After the completion of assessment, goals will be determined and detailed lesson plan will be prepared and documented.  Then the clinician will initiate speech therapy sessions which will typically be for 45minutes in a day. Progress of the child will be evaluated after every 3 months of therapy and required revisions would be made.",
          list: []
        },
        {
          heading: "",
          text: "We are specialized to handle the therapy sessions for children with following conditions.",
          list: []
        },
        {
          heading: "Autism Spectrum Disorders",
          text: "It is a developmental disorder that affects communication and behavior. Children with autism spectrum disorders exhibit impairments in social interaction, impairments in verbal and non verbal communication, restricted interests and repetitive behavior. We mainly use floor time therapy techniques for children with ASD and focus on improving pre-linguistic skills, functional communication, mean length of utterance, following commands etc. based on the language level of the child.",
          list: []
        },
        {
          heading: "Cerebral Palsy",
          text: "Cerebral palsy is caused by neurological condition that affects movements, balance and posture. Children with this condition exhibit problems in oro-motor functions, vegetative skills, developmental milestones, muscle tone and posture. The treatment includes oro-motor exercises, techniques for gross motor and fine motor development and other speech and language therapy based on the language level of the child.",
          list: []
        },
        {
          heading: "Down Syndrome",
          text: "It is a genetic disorder caused by the presence of all or part of a third copy of chromosome 21. It is usually associated with physical growth delays, mild to moderate intellectual disability and characteristics facial features. Since, they will have delays in developmental milestone, therapists deal with the speech and language aspects of the child’s development.",
          list: []
        },
        {
          heading: "Cleft lip and Palate",
          text: "It is the malformation of the upper lip and palate which can occur separately or in combination during the fetal development. This condition is caused by genetical factors, environmental factors or due to prenatal histories. Children with Cleft lip and Palate will have disorders in articulation, resonance, feeding, swallowing, ear infections, dental deviation and vocal hyper function. After the cleft surgery, speech therapists deal with the nasality, vegetative skills and other clarity issues.",
          list: []
        },
        {
          heading: "Learning Disability",
          text: "It is a heterogeneous group of disorders manifested by significant difficulties in the acquisition and use of listening, speaking, reading, writing, reasoning or mathematical skills. Causes of learning disability can be hereditary or genetic, due to prenatal complications, trauma etc. Various types of learning disability include Dyslexia (Reading Disorder), Dysgraphia (writing Disorder) and Dyscalculia (problems in mathematical calculations). Speech and language therapists assess the linguistic performance of the child and help in building the skills which the child lacks.",
          list: []
        }
      ]
    },
    {
      key: 4,
      title: "Physiotherapy",
      description:
        "There are many disorders that require children to seek the help of a physiotherapist. We have a qualified physiotherapist in our institution who deals with all movement disorders, torticollis, posture issues, muscle tone issues, co-ordination issues, toe walking and other orthopedic conditions.",
      url: "",
      image: "physiotherapy.png",
      details: [
        {
          heading: "",
          text: "Our physiotherapy clinic is equipped with all the necessary equipments such as Treadmills, Jogger, Static bicycles, heel exerciser, pulleys, hand weights, parallel bar, balance boards, exercise balls, shoulder wheel, electric stimulator, standing frame and much more.",
          list: []
        },
        {
          heading: "",
          text: "When a child is admitted to the physiotherapy clinic the physiotherapist conducts the preliminary assessments and prepares a detailed treatment plan based on the assessment. Followed by this the therapy sessions are provided and the progress of the child is assessed after every 3 months of treatment. The treatment plan will be revised based on the findings of the assessment.",
          list: []
        },
        {
          heading: "",
          text: "We are specialized to handle the therapy sessions for children with following conditions.",
          list: []
        },
        {
          heading: "Cerebral Palsy",
          text: "It is a neurological condition which affects movement, muscle tone and posture. Physiotherapist deals with the movement impairment and muscle tone issues present in children with cerebral palsy. Physiotherapist also monitors the gross motor and fine motor development of the child and gives treatment for the same.",
          list: []
        },
        {
          heading: "",
          text: "The treatment helps to:-",
          list: ["✔	Manage spasticityManage spasticity", "✔	Improve strength and endurance", "✔	Assist in improving gross and fine motor skills", "✔	Improve motor control", "✔	Improving gait", "✔	Improve functional independence"]
        },
        {
          heading: "Torticollis",
          text: "It is a condition when your child presents with shortened neck muscles causing your child to favor looking and preferring neck movement in one direction. This can later cause facial asymmetry skull deformity and delay in gross motor development.",
          list: []
        },
        {
          heading: "Torticollis",
          text: "The treatment helps to:-",
          list: ["✔	Restore flexibility and strength of the neck ", "✔	Optimize positioning", "✔	Improves gross motor defects"]
        },
        {
          heading: "Posture Issues",
          text: "Physiotherapists also deal with the child’s posture and alignment. The treatment helps to:-",
          list: ["✔	Optimize breathing and alignment"]
        },
        {
          heading: "Tonicity Issues",
          text: "Children with cerebral palsy and other neurological conditions might exhibit issues in muscle tone. Physiotherapist evaluates the tone and help to improve the child’s muscle tone.",
          list: []
        },
        {
          heading: "",
          text: "The treatment helps to:-",
          list: ["✔	Improve strength and endurance", "✔	Increase motor control and balance", "✔	Improve gross motor skills and coordination"]
        },
        {
          heading: "Toe-Walking",
          text: "Physiotherapist assess the causes of toe walking and help tackle it children with neurological issues, autism spectrum disorders etc might exhibit toe-walking.",
          list: []
        },
        {
          heading: "",
          text: "The treatment helps to:-",
          list: ["✔	Assess underlying cause", "✔	Give stretching and strengthening exercises"]
        },
      ]
    },
    {
      key: 5,
      title: "Occupational Therapy",
      description:
        "Occupational therapist helps people of all ages who have physical, sensory or cognitive problems. They also treat children with Autism spectrum disorder, Attention Deficit Hyperactivity disorder (ADHD), Down syndrome etc.",
      url: "",
      image: "Occupational therapy.png",
      details: [
        {
          heading: "",
          text: "Children with Autism spectrum disorder will have sensory issues ( hypo, hyper or mixed sensitivity), while children with ADHD exhibits hyper activity, Impulsivity, Irritability. Some children also possess delayed motor developmental milestones with deficits in pre linguistic skills and other behavioral issues. An occupational therapist can deal with each of these issues and help to improve them.",
          list: []
        },
        {
          heading: "",
          text: "The therapy includes activities to develop pre-linguistic skills, activities to improve the motor skills, activities to improve the sensory and behavioral issues and so on.",
          list: []
        },
        {
          heading: "",
          text: "Assessment will be done first to evaluate the histories and the present level of the child, when they are admitted to the occupational therapy clinic. Followed by this the developmental goals and treatment plan will be prepared.  Once these goals and treatment plan are documented the therapists initiate the therapy sessions. Child’s progress will be evaluated after 3 months of therapy and required revisions would be made to the treatment plan.",
          list: []
        }
      ]
    },
  ]

  return (
    <Grid container spacing={3} style={{ textAlign: "center", paddingTop: 30 }} direction="row" justifyContent="center">
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <Grid container spacing={3} style={{ paddingBottom: 20 }} alignItems="center" direction="column">
          <Typography style={{ display: "block" }} variant="h5">Departments</Typography>
          <Bar />
        </Grid>
      </Box>
      {articles.map(article => (
        <Grid item xs={12} sm={4} key={article.key} >
          <a id={article.title} data-title={article.title}/>
          <DepartmentCard article={article} />
        </Grid>
      ))}
    </Grid>
  )
}
export default Departments
